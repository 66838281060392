import {
  Component, OnInit, ViewChild, ElementRef,
} from '@angular/core';
import { DocumentosService } from 'src/app/services/documentos.service';
import { Texto } from 'src/app/modelos/texto';
import { TextosService } from 'src/app/services/textos.service';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-uva',
  templateUrl: './uva.component.html',
  styleUrls: ['./uva.component.scss'],
})
export class UvaComponent implements OnInit {

  textoUva: Texto;

  baseURL: string;

  @ViewChild('divTextoUva', { static: false }) divTextoUva:ElementRef;

  constructor(
    protected documentosService: DocumentosService,
    protected textosService: TextosService,private env : EnvService
  ) { }

  ngOnInit() {
    this.baseURL = this.env.apiEndpointWebCnc;

    this.textosService.getTexto(2)
      .subscribe(
        (data) => {
          this.textoUva = data;
          this.textoUva.contenido = this.textoUva.contenido.replace("\$", "<abbr role='img' aria-label='Pesos argentinos'>$</abbr> ");
          let regexp = new RegExp('<p','g');
          this.textoUva.contenido = this.textoUva.contenido.replace( regexp, "<p tabIndex='0' ");
          this.divTextoUva.nativeElement.innerHTML = this.textoUva.contenido;
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );
  }

  descargarPDF(id: number) {
    this.documentosService.descargarPDF(id);
  }

}
