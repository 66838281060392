import {
  Component, OnInit, ViewChild, ElementRef,
} from '@angular/core';
import { DocumentosService } from 'src/app/services/documentos.service';
import { TextosService } from 'src/app/services/textos.service';
import { Texto } from 'src/app/modelos/texto';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-prestamos-personales',
  templateUrl: './prestamos-personales.component.html',
  styleUrls: ['./prestamos-personales.component.scss'],
})
export class PrestamosPersonalesComponent implements OnInit {

  textoPrestPersonal: Texto;

  baseURL: string;

  @ViewChild('divTextoPrestPersonal', { static: false }) divTexto:ElementRef;

  constructor(
    protected documentosService: DocumentosService,
    protected textosService: TextosService,
    private env : EnvService,
  ) { }

  ngOnInit() {
    this.baseURL = this.env.apiEndpointWebCnc;

    this.textosService.getTexto(5)
      .subscribe(
        (data) => {
          this.textoPrestPersonal = data;
          this.textoPrestPersonal.contenido = this.textoPrestPersonal.contenido.replace("\$", "<abbr role='img' aria-label='Pesos argentinos'>$</abbr> ");
          let regexp = new RegExp('<p','g');
          this.textoPrestPersonal.contenido = this.textoPrestPersonal.contenido.replace( regexp, "<p tabIndex='0' ");
          regexp = new RegExp('<li','g');
          this.textoPrestPersonal.contenido = this.textoPrestPersonal.contenido.replace( regexp, "<li tabIndex='0' ");
          this.divTexto.nativeElement.innerHTML = this.textoPrestPersonal.contenido;
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );

  }

  descargarPDF(id: number) {
    this.documentosService.descargarPDF(id);
  }

}
