import {
  Component, OnInit, ViewChild, ElementRef,
} from '@angular/core';
import { DocumentosService } from 'src/app/services/documentos.service';
import { Texto } from 'src/app/modelos/texto';
import { TextosService } from 'src/app/services/textos.service';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-prestamos',
  templateUrl: './prestamos.component.html',
  styleUrls: ['./prestamos.component.scss'],
})
export class PrestamosComponent implements OnInit {

  texto: Texto;

  baseURL: string;

  @ViewChild('divTexto', { static: false }) divTexto:ElementRef;

  constructor(
    protected documentosService: DocumentosService,
    protected textosService: TextosService, private env : EnvService
  ) { }

  ngOnInit() {
    this.baseURL = this.env.apiEndpointWebCnc;
   this.textosService.getTexto(1)
      .subscribe(
        (data) => {
          this.texto = data;
          this.texto.contenido = this.texto.contenido.replace("\$", "<abbr role='img' aria-label='Pesos argentinos'>$</abbr> ");
          let regexp = new RegExp('<p','g');
          this.texto.contenido = this.texto.contenido.replace( regexp, "<p tabIndex='0' ");
          
          console.log(this.texto.contenido);
          this.divTexto.nativeElement.innerHTML = this.texto.contenido;
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );
  }

  descargarPDF(id: number) {
    this.documentosService.descargarPDF(id);
  }

}
