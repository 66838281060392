import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quienes-somos-module',
  templateUrl: './quienes-somos-module.component.html',
  styleUrls: ['./quienes-somos-module.component.scss'],
})
export class QuienesSomosModuleComponent  {


}
