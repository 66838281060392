import { Component, OnInit } from '@angular/core';
import { EnvService } from 'src/app/services/env/env.service';
import { DocumentosService } from 'src/app/services/documentos.service';


@Component({
  selector: 'app-elegir-psa',
  templateUrl: './elegir-psa.component.html',
  styleUrls: ['./elegir-psa.component.scss'],
})
export class ElegirPSAComponent implements OnInit {

  baseURL: string;
  fechaLimite: Date = new Date('2024-03-31');
  fechaActual: Date = new Date();

  constructor(protected documentosService: DocumentosService, private env : EnvService) { }

  ngOnInit() {
    this.baseURL = this.env.apiEndpointWebCnc;
  }

  descargarPDF(id: number) {
    this.documentosService.descargarPDF(id);
  }
}
