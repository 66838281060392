import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Sexo } from 'src/app/modelos/sexo';

@Component({
  selector: 'app-crear-cuenta',
  templateUrl: './crear-cuenta.component.html',
  styleUrls: ['./crear-cuenta.component.scss'],
})

export class CrearCuentaComponent implements OnInit {

  firstFormGroup: FormGroup;

  secondFormGroup: FormGroup;

  thirdFormGroup: FormGroup;

  fourthFormGroup: FormGroup;

  sexos: Sexo[] = [
    { id: 0, descripcion: 'Femenino' },
    { id: 1, descripcion: 'Masculino' },
    { id: 2, descripcion: 'Otro' },
  ];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {

    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required],
    });

    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required],
    });

    this.thirdFormGroup = this.formBuilder.group({
      thirdCtrl: ['', Validators.required],
    });

    this.fourthFormGroup = this.formBuilder.group({
      fourthCtrl: ['', Validators.required],
    });

  }

}
