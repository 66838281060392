import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-regret-dialog',
  templateUrl: './regret-dialog.component.html',
  styleUrls: ['./regret-dialog.component.scss']
})
export class RegretDialogComponent {

  constructor(public dialog : MatDialog) { }

  closeDialog(){
    
    this.dialog.closeAll();
  }
}
