import { EnvGlobalService } from './env-global.service';

export const EnvServiceFactory = () => {
  const env = new EnvGlobalService();

  const browserWindow = window || {};
  const browserWindowEnv = browserWindow['__env'] || {};
 
  for (const key in browserWindowEnv) {
    if (browserWindowEnv.hasOwnProperty(key)) {
      env[key] = window['__env'][key];
    }
  }

  return env;
};

export const EnvServiceProvider = {
  provide: EnvGlobalService,
  useFactory: EnvServiceFactory,
  deps: [],
};
