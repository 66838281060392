import { Component, OnInit } from '@angular/core';
import { EstudioJuridico } from 'src/app/modelos/estudioJuridico';

const ESTUDIOS_JURIDICOS: EstudioJuridico[] = [
  {estudioJuridico: 'MH Pena', cuit: '30-70881169-2', direccion: 'Maipú 255, Piso 1, (1084) - C.A.B.A.', telefono: '0800-333-3535', horarioAtencion: 'Lu. a Vi. de 9:00 a 21:00hs.'},
  {estudioJuridico: 'MOPC', cuit: '30-70712489-6', direccion: 'Sarmiento 476, Piso 1, (1041) - C.A.B.A.', telefono: '0800-666-1435', horarioAtencion: 'Lu. a Vi. de 9:00 a 21:00hs.'},
  /*
  {estudioJuridico: 'Morasso & Asociados', cuit: '20-12969030-6', direccion: 'Av. Córdoba 657, Piso 5, (1054) - C.A.B.A.', telefono: '', horarioAtencion: 'Lu. a Vi. de 8:00 a 18:00hs.'},
  {estudioJuridico: 'García Porcel & Asociados', cuit: '20-16037463-3', direccion: 'Maipú 26, Piso 3 G-H, (1405) - C.A.B.A.', telefono: '', horarioAtencion: 'Lu. a Vi. de 9:00 a 18:00hs.'},
  {estudioJuridico: 'FM Abogados', cuit: '30-71702620-5', direccion: 'Sarmiento 476, Piso 6, (1041) - C.A.B.A.', telefono: '', horarioAtencion: 'Lu. a Vi. de 9:00 a 18:00hs.'},
  {estudioJuridico: 'MF Krause Abogados', cuit: '23-13417776-4', direccion: 'Tucumán 1441, Piso 10 C, (1050) = C.A.B.A.', telefono: '', horarioAtencion: 'Lu. a Vi. de 9:00 a 18:00hs'},
  {estudioJuridico: 'Hernán Blousson', cuit: '20-21842921-2', direccion: 'Av. Leandro N. Alem 465, Piso 4, (1003) - C.A.B.A.', telefono: '', horarioAtencion: 'Lu. a Vi. de 9:00 a 18:00hs.'},
  {estudioJuridico: 'Pascual y Forno', cuit: 'J.S. Pascual 20-11986139-0 / G.A. Forno 20-11574536-1', direccion: 'Av. Roque Sáenz Peña 616, Piso 5, (1035) - C.A.B.A.', telefono: '', horarioAtencion: 'Lu. a Vi. de 9:30 a 17:00hs.'},
  {estudioJuridico: 'Proaction Services S.A.', cuit: '30-71144476-5', direccion: 'Maipú 464, Piso 2 201, (1006) - C.A.B.A.', telefono: '', horarioAtencion: 'Lu. a Vi. de 9:00 a 21:00hs.'},
  {estudioJuridico: 'Pena y Asociados', cuit: '30-70881169-2', direccion: 'Maipú 255, Piso 1, (1084) - C.A.B.A.', telefono: '', horarioAtencion: 'Lu. a Vi. de 9:00 a 21:00hs.'},				
  */
];

@Component({
  selector: 'app-estudio-juridico-table',
  templateUrl: './estudio-juridico-table.component.html',
  styleUrls: ['./estudio-juridico-table.component.scss']
})

export class EstudioJuridicoTableComponent {
  displayedColumns: string[] = ['estudioJuridico', 'cuit', 'direccion', 'telefono', 'horarioAtencion'];
  dataSource = ESTUDIOS_JURIDICOS;

}

