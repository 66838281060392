import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html',
  styleUrls: ['./promociones.component.scss'],
})

export class PromocionesComponent {

  imgSrcPeugeot: string = 'assets/img/right-arrow.png';

  imgSrcCitroen: string = 'assets/img/right-arrow.png';

  imgSrcDS: string = 'assets/img/right-arrow.png';

  constructor(private routing: Router) { }


  onMouseOver(marca: string): void {
    if (marca === 'Peugeot') { this.imgSrcPeugeot = 'assets/img/right-arrow-color.png'; }
    if (marca === 'Citroen') { this.imgSrcCitroen = 'assets/img/right-arrow-color.png'; }
    if (marca === 'DS') { this.imgSrcDS = 'assets/img/right-arrow-color.png'; }
  }

  onMouseOut(marca: String): void {
    if (marca === 'Peugeot') { this.imgSrcPeugeot = 'assets/img/right-arrow.png'; }
    if (marca === 'Citroen') { this.imgSrcCitroen = 'assets/img/right-arrow.png'; }
    if (marca === 'DS') { this.imgSrcDS = 'assets/img/right-arrow.png'; }
  }


  navTo(base : String)
  {
      this.routing.navigate([base])
  }
}
