import { Component, OnInit } from '@angular/core';
import { ContactoService } from 'src/app/services/contacto.service';

@Component({
  selector: 'app-snack-bar-contacto',
  templateUrl: './snack-bar-contacto.component.html',
  styleUrls: ['./snack-bar-contacto.component.scss'],
})
export class SnackBarContactoComponent implements OnInit {

  constructor(
    private contacto: ContactoService,
  ) { }

  mensaje: string;

  ngOnInit() {
    this.mensaje = this.contacto.getMensaje();
  }

}
