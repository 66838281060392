import { Component, OnInit } from '@angular/core';
import { SeccionNuestrosProductos } from 'src/app/modelos/seccion-nuestros-productos';
import { SeccionesNuestrosProductosService } from 'src/app/services/secciones-nuestros-productos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-financiacion',
  templateUrl: './financiacion.component.html',
  styleUrls: ['./financiacion.component.scss'],
})
export class FinanciacionComponent implements OnInit {

  secciones: SeccionNuestrosProductos[];

  constructor(private seccionesNuestrosProductosService: SeccionesNuestrosProductosService, private routing :Router) { }

  ngOnInit() {
    this.seccionesNuestrosProductosService.getSecciones()
      .subscribe(
        (data) => {
          this.secciones = data;
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );
  }

  getEstadoSeccion(id: number) {
    if (this.secciones != undefined && this.secciones != null) {
      let seccion = this.secciones.find((item) => item.id === id);
      if (seccion != undefined && seccion.estado != undefined) {
        return this.secciones.find((seccion) => seccion.id === id).estado;
      }
    }
  }

  keyEnterFinance(){
    this.routing.navigate(['/nuestros-productos'])
  }


  keyEnterinsurance()
  {
    this.routing.navigate(['/nuestros-productos/seguros'])
  }

}
