import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaModule } from 'ng-recaptcha';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { HomeComponent } from './home-module/home/home.component';
import { HeaderComponent } from './header/header.component';
import { CarouselComponent } from './home-module/carousel/carousel.component';
import { OfertasComponent } from './home-module/ofertas/ofertas.component';
import { FinanciacionComponent } from './home-module/financiacion/financiacion.component';
import { ElegirPSAComponent } from './home-module/elegir-psa/elegir-psa.component';
import { CardsComponent } from './home-module/cards/cards.component';
import { FooterComponent } from './footer/footer.component';
import { RegretDialogComponent } from './footer/regret-dialog/regret-dialog.component';
import { PoliticaTransparenciaComponent } from './politica-transparencia/politica-transparencia.component';
import { ProductosTazasComponent } from './productos-tazas/productos-tazas.component';
import { CarteleriaBcraComponent } from './carteleria-bcra/carteleria-bcra.component';
import { TerminosCondicionesComponent } from './terminos-condiciones/terminos-condiciones.component';
import { QuienesSomosModuleComponent } from './quienes-somos-module/quienes-somos-module.component';
import { NuestrosProductosModuleComponent } from './nuestros-productos-module/nuestros-productos-module.component';
import { PromocionesComponent } from './nuestros-productos-module/promociones/promociones.component';
import { NuestrosProductosComponent } from './nuestros-productos-module/nuestros-productos/nuestros-productos.component';
import { PrestamosComponent } from './nuestros-productos-module/prestamos/prestamos.component';
import { UvaComponent } from './nuestros-productos-module/uva/uva.component';
import { UsadosComponent } from './nuestros-productos-module/usados/usados.component';
import { RequisitosComponent } from './nuestros-productos-module/requisitos/requisitos.component';
import { LeasingComponent } from './nuestros-productos-module/leasing/leasing.component';
import { SegurosComponent } from './nuestros-productos-module/seguros/seguros.component';
import { PreguntasFrecuentesModuleComponent } from './preguntas-frecuentes-module/preguntas-frecuentes-module.component';
import { ContactoModuleComponent } from './contacto-module/contacto-module.component';
import { ClientsHeaderComponent } from './clients-header/clients-header.component';
import { LoginComponent } from './acceso-clientes-module/login/login.component';
import { CrearCuentaComponent } from './acceso-clientes-module/crear-cuenta/crear-cuenta.component';
import { PasswordComponent } from './acceso-clientes-module/password/password.component';
import { Login2Component } from './acceso-concesionarios-module/login2/login2.component';
import { ConcesionariosHeaderComponent } from './concesionarios-header/concesionarios-header.component';
import { FooterAbsoluteComponent } from './footer-absolute/footer-absolute.component';
import { AcuerdoAdecuaComponent } from './acuerdo-adecua/acuerdo-adecua.component';
import { PromocionesMesComponent } from './promociones-mes/promociones-mes.component';
import { PrestamosPersonalesComponent } from './nuestros-productos-module/prestamos-personales/prestamos-personales.component';
import { SnackBarContactoComponent } from './contacto-module/snack-bar-contacto/snack-bar-contacto.component';
import { BackToTopComponent } from './back-to-top/back-to-top.component';
import { DocumentosService } from './services/documentos.service';
import { ImagenesService } from './services/imagenes.service';
import { TextosService } from './services/textos.service';
import { SeccionesNuestrosProductosService } from './services/secciones-nuestros-productos.service';
import { ErrorModuleComponent } from './error-module/error-module.component';
import { ContactoService } from './services/contacto.service';
import { LegalesComponent } from './legales/legales.component';
import { ComunicadosComponent } from './home-module/comunicados/comunicados.component';
import { ContratosAdhesionComponent } from './contratos-adhesion/contratos-adhesion.component';
import { DefensaConsumidorComponent } from './defensa-consumidor/defensa-consumidor.component';
import { ContactDataDialogComponent } from './footer/contact-data-dialog/contact-data-dialog.component';
import { EstudioJuridicoTableComponent } from './footer/estudio-juridico-table/estudio-juridico-table.component';
//
import {EnvServiceProvider } from 'src/app/services/env/envServiceFactory';
import { MaterialModule } from './material/material.module';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    CarouselComponent,
    OfertasComponent,
    FinanciacionComponent,
    ElegirPSAComponent,
    CardsComponent,
    FooterComponent,
    PoliticaTransparenciaComponent,
    ProductosTazasComponent,
    CarteleriaBcraComponent,
    TerminosCondicionesComponent,
    QuienesSomosModuleComponent,
    NuestrosProductosModuleComponent,
    PromocionesComponent,
    NuestrosProductosComponent,
    PrestamosComponent,
    UvaComponent,
    UsadosComponent,
    RequisitosComponent,
    LeasingComponent,
    SegurosComponent,
    PreguntasFrecuentesModuleComponent,
    ContactoModuleComponent,
    ClientsHeaderComponent,
    LoginComponent,
    CrearCuentaComponent,
    PasswordComponent,
    Login2Component,
    ConcesionariosHeaderComponent,
    FooterAbsoluteComponent,
    AcuerdoAdecuaComponent,
    PromocionesMesComponent,
    PrestamosPersonalesComponent,
    SnackBarContactoComponent,
    BackToTopComponent,
    ErrorModuleComponent,
    LegalesComponent,
    ComunicadosComponent,
    ContratosAdhesionComponent,
    DefensaConsumidorComponent,
    RegretDialogComponent,
    ContactDataDialogComponent,
    EstudioJuridicoTableComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(ROUTES, { useHash: true, relativeLinkResolution: 'legacy' }),
    NgbModule, // Bootstrap
    RecaptchaModule,
    MaterialModule
    ],
  entryComponents: [
    SnackBarContactoComponent,
    LegalesComponent,
    RegretDialogComponent,
    ContactDataDialogComponent,
  ],
  providers: [
    DocumentosService,
    ImagenesService,
    TextosService,
    SeccionesNuestrosProductosService,
    ContactoService,EnvServiceProvider
  ],
  bootstrap: [AppComponent],
})

export class AppModule { }
