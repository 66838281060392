import { Component, OnInit } from '@angular/core';
import { EnvService } from 'src/app/services/env/env.service';
import { DocumentosService } from '../services/documentos.service';

@Component({
  selector: 'app-politica-transparencia',
  templateUrl: './politica-transparencia.component.html',
  styleUrls: ['./politica-transparencia.component.scss'],
})
export class PoliticaTransparenciaComponent implements OnInit {

  baseURL: string;

  constructor(protected documentosService: DocumentosService, private env : EnvService) { }

  ngOnInit() {
    this.baseURL = this.env.apiEndpointWebCnc;
  }

  descargarPDF(id: number) {
    this.documentosService.descargarPDF(id);
  }

  descargarZIP(id: number) {
    this.documentosService.descargarZIP(id);
  }

}
