import {
  Component, OnInit, Input, ViewChild, AfterViewInit,
} from '@angular/core';
import { MatTabGroup } from '@angular/material';
import { SeccionNuestrosProductos } from 'src/app/modelos/seccion-nuestros-productos';
import { SeccionesNuestrosProductosService } from 'src/app/services/secciones-nuestros-productos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nuestros-productos',
  templateUrl: './nuestros-productos.component.html',
  styleUrls: ['./nuestros-productos.component.scss'],
})

export class NuestrosProductosComponent implements OnInit, AfterViewInit {

  @Input() seccion;

  secciones: SeccionNuestrosProductos[];

  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;

  constructor(
    protected seccionesNuestrosProductosService: SeccionesNuestrosProductosService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.seccionesNuestrosProductosService.getSecciones()
      .subscribe(
        (data) => {
          this.secciones = data;
          if (!(this.getEstadoSeccion(1) || this.getEstadoSeccion(7) || this.getEstadoSeccion(8) || this.getEstadoSeccion(9))) {
            this.router.navigate(['/404']);
          }
          if (this.router.url === '/nuestros-productos/seguros' && !this.getEstadoSeccion(9)) {
            this.router.navigate(['/404']);
          }
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );

  }

  ngAfterViewInit() {
    if (this.seccion === 'seguros') {
      this.tabGroup.selectedIndex = 3;
    } else {
      this.tabGroup.selectedIndex = 0;
    }
  }

  getEstadoSeccion(id: number) {
    if (!this.secciones) {
      return false;
    }
    
    const seccion = this.secciones.find((seccion) => seccion.id === id);
    return seccion ? seccion.estado : false;
  }
  
  

 
}
