import { Injectable } from '@angular/core';

@Injectable({
  providedIn:'root'
})
export class EnvService {


  name='';
  apiEndpoint='';
  apiEndpointWebCnc='';
  marketplace='';
  webCnc='';
  espacioCliente = '';
  tagManagerId='';

}
