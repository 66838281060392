import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MotivoContacto } from '../modelos/motivo-contacto';
import { ContactoRequest } from '../modelos/contacto-request';
import { ContactoResponse } from '../modelos/contacto-response';
import { TipoDNI } from '../modelos/tipo-dni';
import { EnvService } from 'src/app/services/env/env.service';

@Injectable({
  providedIn: 'root',
})
export class ContactoService {

  constructor(protected http: HttpClient, private env : EnvService) { }

  mensaje:string;

  getMotivosContacto(): Observable<MotivoContacto[]> {
    return this.http.get<MotivoContacto[]>(`${this.env.apiEndpoint}contacto/motivos.do`);
  }

  enviarFormularioContacto(contactoRequest: ContactoRequest): Observable<ContactoResponse> {
    return this.http.post<ContactoResponse>(`${this.env.apiEndpoint}contacto/email.do`, contactoRequest);
  }

  getTipoDNI(): Observable<TipoDNI[]> {
    return this.http.get<TipoDNI[]>(`${this.env.apiEndpoint}contacto/tipodni.do`);
  }

  // eslint-disable-next-line class-methods-use-this
  setMensaje(mensaje: string) {
    sessionStorage.setItem('mensaje', mensaje);
  }

  // eslint-disable-next-line class-methods-use-this
  getMensaje(): string {
    return sessionStorage.getItem('mensaje');
  }

}
