import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/app/services/env/env.service';
import { Documento } from '../modelos/documento';

@Injectable({
  providedIn: 'root',
})
export class DocumentosService {

  constructor(protected http: HttpClient, private env : EnvService) { }

  getDocumentos(): Observable<Documento[]> {
    return this.http.get<Documento[]>(`${this.env.apiEndpoint}documentos.do`);
  }

  getDocumento(id: number): Observable<Documento> {
    return this.http.get<Documento>(`${this.env.apiEndpoint}documento.do`, {
      params: {
        id: id.toString(),
      },
    });
  }

  getDocumentoPDF(id: number): Observable<Blob> {
    return this.http.get<Blob>(`${this.env.apiEndpointWebCnc}documento-pdf.do`, {
      params: {
        id: id.toString(),
      },
    });
  }

  getDocumentoZIP(id: number): Observable<Blob> {
    return this.http.get<Blob>(`${this.env.apiEndpointWebCnc}documento-zip.do`, {
      params: {
        id: id.toString(),
      },
    });
  }

  descargarPDF(id: number) {
    let documento: Documento;

    this.getDocumento(id)
      .subscribe(
        (data) => {
          documento = data;
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );

    this.getDocumentoPDF(id)
      .subscribe((x) => {
        const newBlob = new Blob([x], { type: 'application/pdf' });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = data;
        link.download = documento.nombreArchivo;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });

  }

  descargarZIP(id: number) {
    let documento: Documento;

    this.getDocumento(id)
      .subscribe(
        (data) => {
          documento = data;
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );

    this.getDocumentoZIP(id)
      .subscribe((x) => {
        const newBlob = new Blob([x], { type: 'application/zip' });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = data;
        link.download = documento.nombreArchivo;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });

  }

}
