import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-module',
  templateUrl: './error-module.component.html',
  styleUrls: ['./error-module.component.scss'],
})
export class ErrorModuleComponent {

  imgHome: string = 'assets/img/home-white.png';

  onMouseOver(color: string): void {
    if (color === 'light-blue') { this.imgHome = 'assets/img/home-light-blue.png'; }
    if (color === 'orange') { this.imgHome = 'assets/img/home-orange.png'; }
  }

  onMouseOut(): void {
    this.imgHome = 'assets/img/home-white.png';
  }

}
