import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.scss'],
})

export class Login2Component implements OnInit {

  isLinear = true;

  firstFormGroup: FormGroup;

  secondFormGroup: FormGroup;

  foods: Food[] = [
    { value: 'value-0', viewValue: 'Steak' },
    { value: 'value-1', viewValue: 'Pizza' },
    { value: 'value-2', viewValue: 'Tacos' },
  ];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required],
    });

    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
  }

}
