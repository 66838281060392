import { Routes } from '@angular/router';
import { PoliticaTransparenciaComponent } from './politica-transparencia/politica-transparencia.component';
import { ProductosTazasComponent } from './productos-tazas/productos-tazas.component';
import { CarteleriaBcraComponent } from './carteleria-bcra/carteleria-bcra.component';
import { TerminosCondicionesComponent } from './terminos-condiciones/terminos-condiciones.component';
import { HomeComponent } from './home-module/home/home.component';
import { QuienesSomosModuleComponent } from './quienes-somos-module/quienes-somos-module.component';
import { NuestrosProductosModuleComponent } from './nuestros-productos-module/nuestros-productos-module.component';
import { PromocionesComponent } from './nuestros-productos-module/promociones/promociones.component';
import { PreguntasFrecuentesModuleComponent } from './preguntas-frecuentes-module/preguntas-frecuentes-module.component';
import { ContactoModuleComponent } from './contacto-module/contacto-module.component';
import { AcuerdoAdecuaComponent } from './acuerdo-adecua/acuerdo-adecua.component';
import { PromocionesMesComponent } from './promociones-mes/promociones-mes.component';
import { ErrorModuleComponent } from './error-module/error-module.component';
import { ContratosAdhesionComponent } from './contratos-adhesion/contratos-adhesion.component';
import { DefensaConsumidorComponent} from './defensa-consumidor/defensa-consumidor.component';

export const ROUTES: Routes = [
  {
    path: '',
    children: [
      { path: '', component: HomeComponent },
      { path: 'politicas-transparencia', component: PoliticaTransparenciaComponent },
      { path: 'productos-tasas', component: ProductosTazasComponent },
      { path: 'carteleria-BCRA', component: CarteleriaBcraComponent },
      { path: 'terminos-condiciones', component: TerminosCondicionesComponent },
      { path: 'quienes-somos', component: QuienesSomosModuleComponent },
      { path: 'nuestros-productos', component: NuestrosProductosModuleComponent },
      { path: 'nuestros-productos/:seccion', component: NuestrosProductosModuleComponent },
      { path: 'promociones', component: PromocionesComponent },
      { path: 'preguntas-frecuentes', component: PreguntasFrecuentesModuleComponent },
      { path: 'contactenos', component: ContactoModuleComponent },
      { path: 'acuerdo-adecua', component: AcuerdoAdecuaComponent },
      { path: 'promociones-mes/:marca', component: PromocionesMesComponent },
      { path: 'contratos-adhesion', component: ContratosAdhesionComponent },
      { path: 'defensa-consumidor', component: DefensaConsumidorComponent },
      { path: '404', component: ErrorModuleComponent },
      { path: '**', redirectTo: '/404' },
    ],
  },
];
