import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-nuestros-productos-module',
  templateUrl: './nuestros-productos-module.component.html',
  styleUrls: ['./nuestros-productos-module.component.scss'],
})

export class NuestrosProductosModuleComponent implements OnInit {

  public seccion: string;

  constructor(private activatedRoute: ActivatedRoute) {
    this.seccion = this.activatedRoute.snapshot.params.seccion;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.seccion = params.seccion || ''; 
      },
    );
  }

}
