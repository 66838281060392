import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-absolute',
  templateUrl: './footer-absolute.component.html',
  styleUrls: ['./footer-absolute.component.scss'],
})
export class FooterAbsoluteComponent {

}
