import {
  Component, OnInit, ViewChild, ElementRef,
} from '@angular/core';
import { DocumentosService } from 'src/app/services/documentos.service';
import { Texto } from 'src/app/modelos/texto';
import { TextosService } from 'src/app/services/textos.service';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-usados',
  templateUrl: './usados.component.html',
  styleUrls: ['./usados.component.scss'],
})
export class UsadosComponent implements OnInit {

  textoUsados: Texto;

  baseURL: string;

  @ViewChild('divTextoUsados', { static: false }) divTextoUsados:ElementRef;

  constructor(
    protected documentosService: DocumentosService,
    protected textosService: TextosService, private env : EnvService
  ) { }

  ngOnInit() {
    this.baseURL = this.env.apiEndpointWebCnc;

    this.textosService.getTexto(3)
      .subscribe(
        (data) => {
          this.textoUsados = data;
          this.textoUsados.contenido = this.textoUsados.contenido.replace("\$", "<abbr role='img' aria-label='Pesos argentinos'>$</abbr> ");
          let regexp = new RegExp('<p','g');
          this.textoUsados.contenido = this.textoUsados.contenido.replace( regexp, "<p tabIndex='0' ");
          regexp = new RegExp('<li','g');
          this.textoUsados.contenido = this.textoUsados.contenido.replace( regexp, "<li tabIndex='0' ");
          this.divTextoUsados.nativeElement.innerHTML = this.textoUsados.contenido;
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );

  }

  descargarPDF(id: number) {
    this.documentosService.descargarPDF(id);
  }

}
