import {
  Component, OnInit, ViewChild, ElementRef,
} from '@angular/core';
import { Texto } from 'src/app/modelos/texto';
import { TextosService } from 'src/app/services/textos.service';

@Component({
  selector: 'app-requisitos',
  templateUrl: './requisitos.component.html',
  styleUrls: ['./requisitos.component.scss'],
})

export class RequisitosComponent implements OnInit {

  texto: Texto;

  @ViewChild('divTexto', { static: false }) divTexto:ElementRef;

  NEXT = 'assets/img/next.svg';

  DOWN = 'assets/img/down.png';

  public firstArrow;

  public secondArrow;

  public isCollapsed = true;

  public isCollapse = true;

  constructor(protected textosService: TextosService) { }

  first() {
    this.isCollapse = true;
    if (this.firstArrow === this.NEXT) {
      this.firstArrow = this.DOWN;
      this.secondArrow = this.NEXT;
    } else {
      this.firstArrow = this.NEXT;
    }
  }

  second() {
    this.isCollapsed = true;
    if (this.secondArrow === this.NEXT) {
      this.secondArrow = this.DOWN;
      this.firstArrow = this.NEXT;
    } else {
      this.secondArrow = this.NEXT;
    }
  }

  ngOnInit() {
    this.firstArrow = this.NEXT;
    this.secondArrow = this.NEXT;

    this.textosService.getTexto(4)
      .subscribe(
        (data) => {
          this.texto = data;
          this.texto.contenido = this.texto.contenido.replace("\$", "<abbr role='img' aria-label='Pesos argentinos'>$</abbr> ");
          let regexp = new RegExp('<p','g');
          this.texto.contenido = this.texto.contenido.replace( regexp, "<p tabIndex='0' ");
          regexp = new RegExp('<li','g');
          this.texto.contenido = this.texto.contenido.replace( regexp, "<li tabIndex='0' ");
          this.divTexto.nativeElement.innerHTML = this.texto.contenido;
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );

  }

}
