import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { RegretDialogComponent } from './regret-dialog/regret-dialog.component';
import { ContactDataDialogComponent } from './contact-data-dialog/contact-data-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(public dialog: MatDialog) { }

  openRegretDialog() {
    const dialogRef = this.dialog.open(RegretDialogComponent);
  }

  openContactDataDialog() {
    const dialogDataRef = this.dialog.open(ContactDataDialogComponent);
  }
}
