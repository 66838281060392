/* eslint-disable no-undef */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { SnackBarContactoComponent } from './snack-bar-contacto/snack-bar-contacto.component';
import { MotivoContacto } from '../modelos/motivo-contacto';
import { ContactoService } from '../services/contacto.service';
import { ContactoRequest } from '../modelos/contacto-request';
import { ContactoResponse } from '../modelos/contacto-response';
import { TipoDNI } from '../modelos/tipo-dni';

@Component({
  selector: 'app-contacto-module',
  templateUrl: './contacto-module.component.html',
  styleUrls: ['./contacto-module.component.scss'],
})
export class ContactoModuleComponent implements OnInit {
  contactoForm: FormGroup;
  motivosContacto: MotivoContacto[] = [];
  tiposDNI: TipoDNI[] = [];
  contactoRequest: ContactoRequest;
  contactoResponse: ContactoResponse;
  recaptchaValid: boolean = null;
  recaptchaResponse: string;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    protected contactoService: ContactoService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.contactoForm = this.fb.group({
      motivo: ['', Validators.required],
      nombreApellido: ['', Validators.required],
      tipoDni: ['', Validators.required],
      dni: ['', [
        Validators.required,
        Validators.maxLength(8),
        Validators.minLength(7),
        Validators.pattern('^[0-9]*$')
      ]],
      nroOperacion: [''],
      telefono: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      texto: ['', Validators.required],
    });

    this.contactoService.getMotivosContacto().subscribe(data => this.motivosContacto = data);
    this.contactoService.getTipoDNI().subscribe(data => this.tiposDNI = data);

    this.contactoRequest = new ContactoRequest();
    this.recaptchaValid = false;
  }

  resolved(recaptchaResponse: string) {
    this.recaptchaResponse = recaptchaResponse;
    this.recaptchaValid = true;
  }

  send() {
    this.contactoRequest = {
      ...this.contactoForm.value,
      recaptchaResponse: this.recaptchaResponse
    };

    this.contactoService.enviarFormularioContacto(this.contactoRequest)
      .subscribe(
        (data) => {
          this.contactoResponse = data;
          this.contactoService.setMensaje(this.contactoResponse.mensaje);

          if (this.contactoResponse.estado === 200) {
            this.openSnackBar();
            this.router.navigate(['/']);
          } else {
            grecaptcha.reset();
            this.recaptchaValid = false;
          }
        }, (error) => {
          console.error(error);
          grecaptcha.reset();
          this.recaptchaValid = false;
        },
      );
  }

  openSnackBar() {
    this.snackBar.openFromComponent(SnackBarContactoComponent, {
      duration: 5000,
    });
  }
}