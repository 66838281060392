import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Imagen } from '../modelos/imagen';
import { EnvService } from 'src/app/services/env/env.service';

@Injectable({
  providedIn: 'root',
})
export class ImagenesService {

  banners: Imagen[];

  constructor(protected http: HttpClient, private env : EnvService) { }

  getBanners(): Observable<Imagen[]> {
    return this.http.get<Imagen[]>(`${this.env.apiEndpoint}banners.do`);
  }

  getPromociones(empresa: string): Observable<Imagen[]> {
    return this.http.get<Imagen[]>(`${this.env.apiEndpoint}promociones.do`, {
      params: {
        empresa,
      },
    });
  }

}
