/* eslint-disable no-param-reassign */
import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { Imagen } from 'src/app/modelos/imagen';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  providers: [NgbCarouselConfig],
})

export class CarouselComponent implements OnInit {

  banners: Imagen[];

  baseURL: string;

  constructor(config: NgbCarouselConfig, protected imagenesService: ImagenesService, private env :EnvService)
    {
    config.interval = 5000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
  }

  ngOnInit() {
    this.baseURL = this.env.apiEndpointWebCnc;

    this.imagenesService.getBanners()
      .subscribe(
        (data) => {
          this.banners = data;
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        },
      );
  }

}
