/* eslint-disable no-return-assign */
import {
  Component, OnInit, ViewChild, AfterViewInit,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-clients-header',
  templateUrl: './clients-header.component.html',
  styleUrls: ['./clients-header.component.scss'],
})

export class ClientsHeaderComponent implements AfterViewInit {
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  icon: string = 'menu';



  ngAfterViewInit(): void {
    this.changeIcon();
  }

  changeIcon() {
    if(this.trigger)
    { this.trigger.menuClosed.subscribe(() => this.icon = 'menu');
      this.trigger.menuOpened.subscribe(() => this.icon = 'menu_open');
  
    }
  }
}
