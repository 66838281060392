import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-defensa-consumidor',
  templateUrl: './defensa-consumidor.component.html',
  styleUrls: ['./defensa-consumidor.component.scss']
})
export class DefensaConsumidorComponent {

}
