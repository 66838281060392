import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvService } from 'src/app/services/env/env.service';
import { Texto } from '../modelos/texto';

@Injectable({
  providedIn: 'root',
})
export class TextosService {

  constructor(protected http: HttpClient, private env : EnvService) { }

  getTexto(id: number):Observable<Texto> {
    return this.http.get<Texto>(`${this.env.apiEndpoint}texto.do`, {
      params: {
        id: id.toString(),
      },
    });
  }
}
