export class ContactoRequest {
    idMotivo: number;

    nombreApellido: string;

    tipoDni: string;

    dni:number;

    nroOpe:number;

    telefono: string;

    email: string;

    texto: string;

    recaptchaResponse: String;

}
