import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { EnvGlobalService } from 'src/app/services/env/env-global.service';
import { EnvService } from 'src/app/services/env/env.service';

declare let gtag:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {

  title = 'PSA-maqueta';

  // Manager Google Analytics
  constructor(private router: Router,private envGlobal : EnvGlobalService , private env : EnvService ) {
    this.getEnvironment(window.location.hostname)
    // Google Analytics
    const navEndEvents$ = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
      );
    navEndEvents$.subscribe((event: NavigationEnd) => {
      if (typeof gtag === 'function') {
        gtag('config', env.tagManagerId, {
          page_path: event.urlAfterRedirects,
        });
      } else {
        // eslint-disable-next-line no-console
        console.warn('gtag: ', gtag);
      }
    });
    // Fin Google Analytics
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.setGTagManager();
  }

  // eslint-disable-next-line class-methods-use-this
  private setGTagManager() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://www.googletagmanager.com/gtag/js?id=${this.env.tagManagerId}`;

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(s);

    const s1 = document.createElement('script');
    s1.innerHTML = 'window.dataLayer = window.dataLayer || [];'
        + 'function gtag(){dataLayer.push(arguments);}'
        + "gtag('js', new Date());";
    head.appendChild(s1);
    if (this.env.name !== 'prod') {
      const scriptInfo = document.createElement('script');
      scriptInfo.innerHTML = `console.warn('Advertencia: Este ambiente no es productivo (Dominio: ' + document.domain.toLowerCase() + ', Ambiente:${this.env.name}), por tal evita registrar metricas en analytics productivo. En este caso se utiliza el siguiente ID de analytics: ${this.env.tagManagerId}')`;
      if (!head.contains(scriptInfo)) {
        head.appendChild(scriptInfo);
      }
    }
  }


    //SIEMPRE COMPILAR PARA PROD.
    public getEnvironment(hostname) : void {
      this.env.tagManagerId = this.envGlobal.tagManagerId;
      if(hostname == 'localhost')
      {
        this.env.name = 'local';
         this.env.apiEndpoint = this.envGlobal.apiEndpointLocal;
         this.env.apiEndpointWebCnc = this.envGlobal.apiEndpointWebCncLocal;
         this.env.marketplace = this.envGlobal.marketplaceDesa;
         this.env.webCnc = this.envGlobal.webCncDesa;
         this.env.espacioCliente = this.envGlobal.espacioClienteDesa;
      }
      else if(hostname == 'was90desa1' || hostname == 'psa-play.psafinance.com.ar' )
      {
        this.env.name = 'desa';
        this.env.apiEndpoint = this.envGlobal.apiEndpointDesa;
         this.env.apiEndpointWebCnc = this.envGlobal.apiEndpointWebCncDesa;
         this.env.espacioCliente = this.envGlobal.espacioClienteDesa;
         this.env.marketplace = this.envGlobal.marketplaceDesa;
         this.env.webCnc = this.envGlobal.webCncDesa;
      }   
      else if(hostname == 'was90test1' || hostname == 'testpsa-qa.bbva.com.ar')
      {
        this.env.name = 'test';
        this.env.apiEndpoint = this.envGlobal.apiEndpointTest;
        this.env.apiEndpointWebCnc = this.envGlobal.apiEndpointWebCncTest;
        this.env.espacioCliente = this.envGlobal.espacioClienteTest;
        this.env.marketplace = this.envGlobal.marketplaceTest;
        this.env.webCnc = this.envGlobal.webCncTest;
      }
      else if(hostname == 'was90prodinternet1') //PROD PRIVADO
      {  
        this.env.name = 'prod';
        this.env.apiEndpoint = this.envGlobal.apiEndpointProd2;
        this.env.apiEndpointWebCnc = this.envGlobal.apiEndpointWebCncProd;
        this.env.marketplace = this.envGlobal.marketplaceProd;
        this.env.espacioCliente = this.envGlobal.espacioClienteProd;
        this.env.webCnc = this.envGlobal.webCncProd;
        this.env.tagManagerId = this.envGlobal.tagManagerIdProd;
      }else if(hostname == 'www.psafinance.com.ar'){ //PROD PUBLICO
        this.env.name = 'prod';
        this.env.apiEndpoint = this.envGlobal.apiEndpointProd;
        this.env.apiEndpointWebCnc = this.envGlobal.apiEndpointWebCncProd;
        this.env.espacioCliente = this.envGlobal.espacioClienteProd;
        this.env.marketplace = this.envGlobal.marketplaceProd;
        this.env.webCnc = this.envGlobal.webCncProd;
        
        this.env.tagManagerId = this.envGlobal.tagManagerIdProd;
      }
    }
}

