import {
  Component, OnInit, ViewChild, ElementRef,
} from '@angular/core';
import { Texto } from 'src/app/modelos/texto';
import { TextosService } from 'src/app/services/textos.service';

@Component({
  selector: 'app-seguros',
  templateUrl: './seguros.component.html',
  styleUrls: ['./seguros.component.scss'],
})
export class SegurosComponent implements OnInit {

  texto: Texto;

  @ViewChild('divTexto', { static: false }) divTexto:ElementRef;

  constructor(protected textosService: TextosService) { }

  ngOnInit() {
    this.textosService.getTexto(7)
      .subscribe(
        (data) => {
          this.texto = data;
          this.texto.contenido = this.texto.contenido.replace("\$", "<abbr role='img' aria-label='Pesos argentinos'>$</abbr> ");
          let regexp = new RegExp('<p','g');
          this.texto.contenido = this.texto.contenido.replace( regexp, "<p tabIndex='0' ");
          this.divTexto.nativeElement.innerHTML = this.texto.contenido;
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );

  }

}
