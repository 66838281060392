import { Component, OnInit } from '@angular/core';
import { EnvService } from 'src/app/services/env/env.service';
import { DocumentosService } from '../services/documentos.service';

@Component({
  selector: 'app-productos-tazas',
  templateUrl: './productos-tazas.component.html',
  styleUrls: ['./productos-tazas.component.scss'],
})

export class ProductosTazasComponent implements OnInit {

  public promocionesCollapsed = true;

  NEXT = 'assets/img/next-black.svg';

  DOWN = 'assets/img/down-black.png';

  public arrow;

  baseURL: string;

  constructor(protected documentosService: DocumentosService,private env : EnvService) { }

  promociones() {
    this.promocionesCollapsed = !this.promocionesCollapsed;
    if (this.arrow === this.NEXT) {
      this.arrow = this.DOWN;
    } else {
      this.arrow = this.NEXT;
    }
  }

  ngOnInit() {
    this.arrow = this.NEXT;

    this.baseURL = this.env.apiEndpointWebCnc;
  }

  descargarPDF(id: number) {
    this.documentosService.descargarPDF(id);
  }
}
