import {
  Component, OnInit, AfterViewInit, Inject, ViewChild, ElementRef,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  legales: string;
}

@Component({
  selector: 'app-legales',
  templateUrl: './legales.component.html',
  styleUrls: ['./legales.component.scss'],
})
export class LegalesComponent implements AfterViewInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  @ViewChild('divLegales', { static: false }) divLegales:ElementRef;


  ngAfterViewInit() {
    this.divLegales.nativeElement.innerHTML = this.data.legales;
  }
}
