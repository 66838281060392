import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-contact-data-dialog',
  templateUrl: './contact-data-dialog.component.html',
  styleUrls: ['./contact-data-dialog.component.scss']
})
export class ContactDataDialogComponent{

  constructor(public dialog : MatDialog) { }


  closeDialog(){
    this.dialog.closeAll();
  }
}
