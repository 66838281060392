import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DocumentosService } from 'src/app/services/documentos.service';
import { ImagenesService } from '../services/imagenes.service';
import { Imagen } from '../modelos/imagen';
import {MatDialog} from '@angular/material/dialog';
import { EnvService } from 'src/app/services/env/env.service';
import { LegalesComponent } from '../legales/legales.component';

@Component({
  selector: 'app-promociones-mes',
  templateUrl: './promociones-mes.component.html',
  styleUrls: ['./promociones-mes.component.scss'],
})

export class PromocionesMesComponent implements OnInit {

  public marcas = new Map();

  marca: {codigo: string, nombre: string} ;

  promociones: Imagen[];

  baseURL: string;

  promocionesId: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    protected documentosService: DocumentosService,
    protected imagenesService: ImagenesService,
    public dialog: MatDialog,
    private router: Router,private env : EnvService
  ) { }

  ngOnInit() {
    this.marcas
      .set('pgt', 'PEUGEOT')
      .set('ctn', 'CITROEN')
      .set('ds', 'DS');

    this.marca = {
      codigo: ' ',
      nombre: ' ',
    };

    this.baseURL = this.env.apiEndpointWebCnc;


    if (!this.marcas.has(this.activatedRoute.snapshot.params.marca)) {
      this.router.navigate(['/404']);
    }

    this.marca = {
      codigo: this.activatedRoute.snapshot.params.marca,
      nombre: this.marcas.get(this.marca.codigo),
    };

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.marca = {
          codigo: params.marca,
          nombre: this.marcas.get(this.marca.codigo),
        };

        if (this.marca.codigo === 'pgt') {
          this.promocionesId = 41;
        } else if (this.marca.codigo === 'ctn') {
          this.promocionesId = 42;
        } else {
          this.promocionesId = 43;
        }
      },
    );

    this.imagenesService.getPromociones(this.marca.codigo.toUpperCase())
      .subscribe(
        (data) => {
          this.promociones = data;
        },
        (error) => {
          console.log(error);
        },
      );
  }


  descargarPDF() {
    if (this.marca.codigo === 'pgt') {
      this.documentosService.descargarPDF(41);
    } else if (this.marca.codigo === 'ctn') {
      this.documentosService.descargarPDF(42);
    } else {
      this.documentosService.descargarPDF(43);
    }
  }

  mostrarLegales(id: number) {
    this.dialog.open(LegalesComponent, {
      data: {
        legales: this.promociones.find((promocion) => promocion.id === id).legales,
      },
    });
  }
}