import { Component, OnInit } from '@angular/core';
import {
  FormControl, Validators,
} from '@angular/forms';
import { MyErrorStateMatcher } from 'src/app/common/error-state-matcher';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent {

  dniFormControl = new FormControl('', [
    Validators.required,
  ]);

  passFormControl = new FormControl('', [
    Validators.required,
  ]);

  matcher = new MyErrorStateMatcher();




}
