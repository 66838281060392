import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatCardModule, MatButtonModule, MatSelectModule, MatTabsModule, MatIconModule, MatInputModule,
    MatStepperModule, MatDatepickerModule, MatNativeDateModule, MatGridListModule, MatMenuModule,
    MatSnackBarModule, MatDividerModule, MatDialogModule, MatTableModule, MatExpansionModule, MatFormFieldModule 
  } from '@angular/material';



@NgModule({
  declarations: [],
  imports: [ CommonModule,
    MatCardModule, MatButtonModule, MatSelectModule, MatTabsModule, MatIconModule, MatInputModule,
    MatStepperModule, MatDatepickerModule, MatNativeDateModule, MatGridListModule, MatMenuModule,
    MatSnackBarModule, MatDividerModule, MatDialogModule, MatTableModule, MatExpansionModule ,MatFormFieldModule
  ],
  exports: [
    CommonModule,
    MatCardModule, MatButtonModule, MatSelectModule, MatTabsModule, MatIconModule, MatInputModule,
    MatStepperModule, MatDatepickerModule, MatNativeDateModule, MatGridListModule, MatMenuModule,
    MatSnackBarModule, MatDividerModule, MatDialogModule, MatTableModule, MatExpansionModule ,MatFormFieldModule
  ]
})
export class MaterialModule { }
