import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contratos-adhesion',
  templateUrl: './contratos-adhesion.component.html',
  styleUrls: ['./contratos-adhesion.component.scss'],
})
export class ContratosAdhesionComponent {

}
