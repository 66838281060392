import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carteleria-bcra',
  templateUrl: './carteleria-bcra.component.html',
  styleUrls: ['./carteleria-bcra.component.scss'],
})
export class CarteleriaBcraComponent {


}
