import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acuerdo-adecua',
  templateUrl: './acuerdo-adecua.component.html',
  styleUrls: ['./acuerdo-adecua.component.scss'],
})
export class AcuerdoAdecuaComponent {

}
