import { Component, OnInit } from '@angular/core';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-comunicados',
  templateUrl: './comunicados.component.html',
  styleUrls: ['./comunicados.component.scss'],
})
export class ComunicadosComponent implements OnInit {

  baseURL: string;

 


  constructor(private env : EnvService) { }

  ngOnInit() {
    this.baseURL = this.env.apiEndpointWebCnc;
  }

}
