import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-concesionarios-header',
  templateUrl: './concesionarios-header.component.html',
  styleUrls: ['./concesionarios-header.component.scss'],
})
export class ConcesionariosHeaderComponent {


}
