import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preguntas-frecuentes-module',
  templateUrl: './preguntas-frecuentes-module.component.html',
  styleUrls: ['./preguntas-frecuentes-module.component.scss'],
})

export class PreguntasFrecuentesModuleComponent implements OnInit {

  public prestamoFinanciacion = true;

  public prestamoPrendario = true;

  public prestamoPersonal = true;

  public prestamoUva = true;

  public prestamoLeasing = true;

  public prestamoSeguros = true;;

  NEXT = 'assets/img/next-grey.svg';

  DOWN = 'assets/img/down-grey.png';

  public arrowFinanciacion;

  public arrowPrendario;

  public arrowPersonal;

  public arrowUva;

  public arrowLeasing;

  public arrowSeguros;

  financiacion() {
    this.prestamoPrendario = true;
    this.prestamoPersonal = true;
    this.prestamoUva = true;
    this.prestamoLeasing = true;
    this.prestamoSeguros = true;
    if (this.arrowFinanciacion === this.NEXT) {
      this.arrowFinanciacion = this.DOWN;
      this.arrowPrendario = this.NEXT;
      this.arrowPersonal = this.NEXT;
      this.arrowUva = this.NEXT;
      this.arrowLeasing = this.NEXT;
      this.arrowSeguros = this.NEXT;
    } else {
      this.arrowFinanciacion = this.NEXT;
    }
    this.prestamoFinanciacion = !this.prestamoFinanciacion;
  }

  prendario() {
    this.prestamoFinanciacion = true;
    this.prestamoPersonal = true;
    this.prestamoUva = true;
    this.prestamoLeasing = true;
    this.prestamoSeguros = true;
    if (this.arrowPrendario === this.NEXT) {
      this.arrowPrendario = this.DOWN;
      this.arrowFinanciacion = this.NEXT;
      this.arrowPersonal = this.NEXT;
      this.arrowUva = this.NEXT;
      this.arrowLeasing = this.NEXT;
      this.arrowSeguros = this.NEXT;
    } else {
      this.arrowPrendario = this.NEXT;
    }
    this.prestamoPrendario = !this.prestamoPrendario;
  }

  personal() {
    this.prestamoFinanciacion = true;
    this.prestamoPrendario = true;
    this.prestamoUva = true;
    this.prestamoLeasing = true;
    this.prestamoSeguros = true;
    if (this.arrowPersonal === this.NEXT) {
      this.arrowPersonal = this.DOWN;
      this.arrowFinanciacion = this.NEXT;
      this.arrowPrendario = this.NEXT;
      this.arrowUva = this.NEXT;
      this.arrowLeasing = this.NEXT;
      this.arrowSeguros = this.NEXT;
    } else {
      this.arrowPersonal = this.NEXT;
    }
    this.prestamoPersonal = !this.prestamoPersonal;
  }

  uva() {
    this.prestamoFinanciacion = true;
    this.prestamoPrendario = true;
    this.prestamoPersonal = true;
    this.prestamoLeasing = true;
    this.prestamoSeguros = true;
    if (this.arrowUva === this.NEXT) {
      this.arrowUva = this.DOWN;
      this.arrowFinanciacion = this.NEXT;
      this.arrowPrendario = this.NEXT;
      this.arrowPersonal = this.NEXT;
      this.arrowLeasing = this.NEXT;
      this.arrowSeguros = this.NEXT;
    } else {
      this.arrowUva = this.NEXT;
    }
    this.prestamoUva = !this.prestamoUva
  }

  leasing() {
    this.prestamoFinanciacion = true;
    this.prestamoPrendario = true;
    this.prestamoPersonal = true;
    this.prestamoUva = true;
    this.prestamoSeguros = true;
    if (this.arrowLeasing === this.NEXT) {
      this.arrowLeasing = this.DOWN;
      this.arrowFinanciacion = this.NEXT;
      this.arrowPrendario = this.NEXT;
      this.arrowPersonal = this.NEXT;
      this.arrowUva = this.NEXT;
      this.arrowSeguros = this.NEXT;
    } else {
      this.arrowLeasing = this.NEXT;
    }
    this.prestamoLeasing = !this.prestamoLeasing;
  }

  seguro() {
    this.prestamoFinanciacion = true;
    this.prestamoPrendario = true;
    this.prestamoPersonal = true;
    this.prestamoUva = true;
    this.prestamoLeasing = true;
    if (this.arrowSeguros === this.NEXT) {
      this.arrowSeguros = this.DOWN;
      this.arrowFinanciacion = this.NEXT;
      this.arrowPrendario = this.NEXT;
      this.arrowPersonal = this.NEXT;
      this.arrowUva = this.NEXT;
      this.arrowLeasing = this.NEXT;
    } else {
      this.arrowSeguros = this.NEXT;
    }
    this.prestamoSeguros = ! this.prestamoSeguros;
  }

  ngOnInit() {
    this.arrowFinanciacion = this.NEXT;
    this.arrowPrendario = this.NEXT;
    this.arrowPersonal = this.NEXT;
    this.arrowUva = this.NEXT;
    this.arrowLeasing = this.NEXT;
    this.arrowSeguros = this.NEXT;
  }

}
