import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ofertas',
  templateUrl: './ofertas.component.html',
  styleUrls: ['./ofertas.component.scss'],
})
export class OfertasComponent {

  imgSrcPeugeot: string = 'assets/img/right-arrow.png';

  imgSrcCitroen: string = 'assets/img/right-arrow.png';

  imgSrcDS: string = 'assets/img/right-arrow.png';

  constructor(private routing : Router) { }

  onMouseOver(marca: string): void {
    if (marca === 'Peugeot') { this.imgSrcPeugeot = 'assets/img/right-arrow-color.png'; }
    if (marca === 'Citroen') { this.imgSrcCitroen = 'assets/img/right-arrow-color.png'; }
    if (marca === 'DS') { this.imgSrcDS = 'assets/img/right-arrow-color.png'; }
  }

  onMouseOut(marca: String): void {
    if (marca === 'Peugeot') { this.imgSrcPeugeot = 'assets/img/right-arrow.png'; }
    if (marca === 'Citroen') { this.imgSrcCitroen = 'assets/img/right-arrow.png'; }
    if (marca === 'DS') { this.imgSrcDS = 'assets/img/right-arrow.png'; }
  }
 

  keyEnterPgt()
  {
      this.routing.navigate(['/promociones-mes/pgt'])
  }


  keyEnterCtn()
  {
      this.routing.navigate(['/promociones-mes/ctn'])
  }


  keyEnterDS()
  {
      this.routing.navigate(['/promociones-mes/ds'])
  }
}
