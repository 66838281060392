import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SeccionNuestrosProductos } from '../modelos/seccion-nuestros-productos';
import { EnvService } from 'src/app/services/env/env.service';

@Injectable({
  providedIn: 'root',
})
export class SeccionesNuestrosProductosService {

  constructor(protected http: HttpClient, private env : EnvService) { }

  getSecciones(): Observable<SeccionNuestrosProductos[]> {
    return this.http.get<SeccionNuestrosProductos[]>(`${this.env.apiEndpoint}nuestros-productos/secciones.do`);
  }

  getSeccion(id: number): Observable<SeccionNuestrosProductos> {
    return this.http.get<SeccionNuestrosProductos>(`${this.env.apiEndpoint}nuestros-productos/seccion.do`, {
      params: {
        id: id.toString(),
      },
    });
  }
}
