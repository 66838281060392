/* eslint-disable no-return-assign */
import {
  Component, OnInit, ViewChild, AfterViewInit,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { SeccionesNuestrosProductosService } from '../services/secciones-nuestros-productos.service';
import { SeccionNuestrosProductos } from '../modelos/seccion-nuestros-productos';
import { EnvService } from '../services/env/env.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  icon: string = 'menu';

  imgHome: string = 'assets/img/home-white.png';

  secciones: SeccionNuestrosProductos[];

  marketplace: string = this.env.marketplace;

  webCnc: string = this.env.webCnc;


  constructor(private seccionesNuestrosProductosService: SeccionesNuestrosProductosService, private env : EnvService) { }

  ngOnInit() {
    this.seccionesNuestrosProductosService.getSecciones()
      .subscribe(
        (data) => {
          this.secciones = data;
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        },
      );
  }

  ngAfterViewInit(): void {
    this.changeIcon();
  }

  onMouseOver(color: string): void {
    if (color === 'light-blue') { this.imgHome = 'assets/img/home-light-blue.png'; }
    if (color === 'orange') { this.imgHome = 'assets/img/home-orange.png'; }
  }

  onMouseOut(): void {
    this.imgHome = 'assets/img/home-white.png';
  }

  changeIcon() {
    this.trigger.menuClosed.subscribe(() => this.icon = 'menu');
    this.trigger.menuOpened.subscribe(() => this.icon = 'menu_open');
  }

  getEstadoSeccion(id: number) {
    if (this.secciones != undefined && this.secciones != null) {
      let seccion = this.secciones.find((item) => item.id === id);
      if (seccion != undefined && seccion.estado != undefined) {
        return this.secciones.find((seccion) => seccion.id === id).estado;
      }
    }
  }

  goToEspacioClientes() {
    window.open(this.env.espacioCliente, '_self')
  }

  goToWebCNC() {
    window.open(this.env.webCnc, '_self')
  }
}
