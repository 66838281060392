import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvGlobalService {

  //LOCAL
  public apiEndpointLocal= '';
  public apiEndpointWebCncLocal= '';
  public tagManagerId= '';

  //DESA
  public apiEndpointDesa= '';
  public apiEndpointWebCncDesa= '';
  public marketplaceDesa= '';
  public webCncDesa= '';
  public espacioClienteDesa = '';
 
  //TEST  
  public apiEndpointTest= '';
  public apiEndpointWebCncTest= '';
  public marketplaceTest= '';
  public webCncTest= '';
  public espacioClienteTest = '';
 
 //PROD
 public apiEndpointProd= '';
 public apiEndpointProd2= '';
 public apiEndpointWebCncProd= '';
 public marketplaceProd= '';
 public webCncProd= '';
 public espacioClienteProd = '';
 public tagManagerIdProd= '';


}
