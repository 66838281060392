import { Component, OnInit } from '@angular/core';
import { EnvService } from 'src/app/services/env/env.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})

export class CardsComponent {



  constructor(private env : EnvService) { }

  goToEspacioClientes() {
    window.open(this.env.espacioCliente, '_self')
  }

  goToRegister() {
    window.open(this.env.espacioCliente + '#/psa-espacio-clientes-backend/register' , '_self')
  }

}
